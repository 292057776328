.App {
  text-align: center;
}

.App-logo {
  /* animation: App-logo-spin infinite 20s linear; */
  height: 20vmin;
  fill: #404040;
  /*   -webkit-filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, .7));
  filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, .7)); */
}

.App-header {
  background-color: #fff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #404040;
}

.copyright {
  font-size: 0.875rem;
  width: 50vw;
}
@media (max-width: 768px) {
  .copyright {
    width: 90%;
  }
}

h1 {
  font-size: 1em;
}
